import { sortBy, take } from '../../../utils/array'
import { flow } from '../../../utils/function'
import { setStepLocation } from '../../itinerary/itinerarySlice'

import {
  formatLabelWithInputValueKeyword,
  isLabelMatchingInputValueKeyword
} from '../../suggest/suggest.selectors.utils'

const formatHistoryEntry = ({ searchHistoryEntry, isLabelsFormatted, normalizedCurrentSearch, currentSearch }) => {
  return {
    labels: isLabelsFormatted
      ? searchHistoryEntry.split_label.map(formatLabelWithInputValueKeyword(normalizedCurrentSearch, currentSearch))
      : searchHistoryEntry?.split_label,
    date: searchHistoryEntry?.date,
    type: searchHistoryEntry?.type,
    subtype: {
      id: 'history'
    }
  }
}

export const lastTenHistoryEntries = searchHistory =>
  flow(
    array => sortBy(array, 'date', 'desc'),
    array => take(array, 10),
    array => array.map(searchHistoryEntry => formatHistoryEntry({ searchHistoryEntry }))
  )(searchHistory)

export const lastSearchHistoryEntries = (normalizedCurrentSearch, currentSearch, searchHistory) =>
  flow(
    array =>
      array.filter(searchHistoryEntry =>
        isLabelMatchingInputValueKeyword(normalizedCurrentSearch, searchHistoryEntry.split_label.join(' '))
      ),
    array => sortBy(array, 'date', 'desc'),
    array => take(array, 3),
    array =>
      array.map(searchHistoryEntry =>
        formatHistoryEntry({
          searchHistoryEntry,
          isLabelsFormatted: true,
          normalizedCurrentSearch,
          currentSearch
        })
      )
  )(searchHistory)

export const getStepDataFromActionPayload = (actionType, payload) => {
  if (actionType === setStepLocation.type) {
    const {
      location: { label, split_label, type }
    } = payload

    return { label, split_label, type }
  }

  const {
    addresses: [{ label, split_label, type }]
  } = payload

  return {
    label,
    split_label,
    type
  }
}
