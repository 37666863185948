import { createCustomSelector } from '../../domain/utils/react-reselect'
import { selectGeoentityState } from './geoentity.selectors'
import { DEFAULT_FUEL_ID, FUEL_DEFAULT_COLOR } from './geoentity.constants'
import { selectGeoentityFilterParameters } from './filter.selectors'

export const selectFuelInformation = createCustomSelector(selectGeoentityState, (geoentity = {}) => geoentity?.fuels)

export const selectCurrentFuelFilterId = createCustomSelector(
  selectGeoentityFilterParameters,
  (parameters = {}) => parameters['combobox-fuel']?.currentFuel ?? DEFAULT_FUEL_ID
)

export const selectCurrentFuelFilterIdWithoutFallback = createCustomSelector(
  selectGeoentityFilterParameters,
  (parameters = {}) => parameters['combobox-fuel']?.currentFuel
)

export const selectCurrentFuelFilterInfo = createCustomSelector(
  selectFuelInformation,
  selectCurrentFuelFilterId,
  (info, filterId) => info?.find(fuel => fuel.id === filterId)
)

export const selectCurrentFuelColor = createCustomSelector(
  selectCurrentFuelFilterId,
  selectFuelInformation,
  (fuelId, fuelInfos) => fuelInfos?.find(({ id }) => id === fuelId)?.color?.hex ?? FUEL_DEFAULT_COLOR
)
