import axios from 'axios'
import debug from 'debug'
import { parse } from 'qs'
import { isDevelopment } from '../../parameters'
import ServerError from '../error/ServerError'
import TimeoutError from '../error/TimeoutError'
import getRequestAgents from './requestAgent'
import { forceProtocol, paramsSerializerRepeat } from './url'

const d = debug('request')
const dMock = debug('mock')

export const LONG_TIMEOUT = 20_000
export const DEFAULT_SERVER_TIMEOUT = 2_800

export const getTimeout = () => (__BROWSER__ ? LONG_TIMEOUT : DEFAULT_SERVER_TIMEOUT)

const REQUEST_TIMEOUT = 408
const GATEWAY_TIMEOUT = 504

const requestAgents = getRequestAgents() || {}

let useMock = false
let mocks = process.env.TEST && isDevelopment ? {} : undefined

const serveMock = urlWithParams => {
  const isSvg = urlWithParams.endsWith('svg')
  const protocolLessUrl = urlWithParams.replace(/https?:/, '')
  !isSvg && dMock(`looking in mocks for ${protocolLessUrl}`)
  const mock = mocks[protocolLessUrl]
  !isSvg && dMock(`< ${mock ? '200' : '*404*'} ${protocolLessUrl} (mock)`)
  if (!mock) {
    const fakeAxios404Error = new Error()
    fakeAxios404Error.response = { status: 404 }
    return Promise.reject(fakeAxios404Error)
  }
  return Promise.resolve({
    status: 200,
    headers: { 'content-length': mock.length },
    data: mock
  })
}

const toQueryString = obj => {
  const start = Object.keys(obj).length > 0 ? '?' : ''
  return `${start}${paramsSerializerRepeat(obj)}`
}

const requestStarts = (url, timeout = getTimeout()) => {
  const startTime = Date.now()
  const controller = new AbortController()
  const getRequestDuration = () => Date.now() - startTime
  const timeoutHandler = setTimeout(() => {
    controller.abort(`Request: TIMEOUT in ${getRequestDuration()}ms (vs ${timeout}) for ${url}`)
  }, timeout)

  return {
    getRequestDuration,
    requestClose: () => clearTimeout(timeoutHandler),
    signal: controller.signal
  }
}

const getServerHeaders = () =>
  Promise.resolve().then(() => {
    if (__SERVER__) {
      return import('../../node/AppManifest').then(({ getAppVersion }) => {
        const appVersion = getAppVersion()
        return {
          Referer: `WebSite/${appVersion}`,
          'User-Agent': `WebNessie/${appVersion}`,
          'Accept-Encoding': 'gzip, deflate'
        }
      })
    }
    return {}
  })

export const getHeaders = ({ host, authorization, apikey, locale } = {}) =>
  getServerHeaders().then(serverHeader =>
    Object.assign(
      serverHeader,
      locale ? { 'Accept-Language': locale } : {},
      host ? { Host: host } : {},
      authorization ? { Authorization: authorization } : {},
      apikey ? { apikey } : {}
    )
  )

const doRequest = method => (service, config) => {
  const { url, host, apikey } = service instanceof Object ? service : { url: service }
  const urlFormated = forceProtocol(url)
  const params = config?.params ?? {}
  const data = config?.data ?? {}
  const authorization = config?.authorization
  const locale = config?.locale
  if (d.enabled) {
    d('request >', method, urlFormated, 'Host:', host)
  }
  if (useMock) return serveMock(url)

  return getHeaders({ host, authorization, apikey, locale })
    .then(headers =>
      axios({
        url: urlFormated,
        method,
        data,
        params,
        headers
      })
    )
    .then(response => {
      if (d.enabled) {
        d('request <', response?.status, url)
      }
      return response
    })
    .catch(error => {
      console.error('request <', error?.response?.status, url)
      throw error
    })
}

export default class request {
  static useOnlyMock() {
    if (!process.env.TEST) console.error('request : set to use only mocks - DO THIS ONLY FOR TESTS')
    if (!isDevelopment) return
    useMock = true
  }

  static configureMocks(m) {
    if (!process.env.TEST || !isDevelopment) {
      console.error('configureMocks called !')
    } else {
      dMock('Mocks configured', Object.keys(m))
      mocks = { ...mocks, ...m }
    }
  }

  static get(service, config) {
    const { url, host, apikey } = service instanceof Object ? service : { url: service }
    const urlFormated = forceProtocol(url)
    const params = config?.params ?? {}
    const authorization = config?.authorization
    const responseType = config?.responseType
    const paramsSerializer = {
      parse,
      serialize: config?.paramsSerializer ?? paramsSerializerRepeat
    }
    const timeout = config?.timeout
    const silent = config?.silent
    const locale = config?.locale

    const urlWithParams = urlFormated + toQueryString(params)

    if (d.enabled) {
      d('request > get', urlWithParams)
    }
    if (useMock) return serveMock(urlWithParams)
    const { getRequestDuration, signal, requestClose } = requestStarts(urlWithParams, timeout)
    return getHeaders({ host, authorization, apikey, locale })
      .then(headers =>
        axios({
          ...requestAgents,
          url: urlFormated,
          method: 'GET',
          responseType,
          params,
          paramsSerializer,
          headers,
          signal
        })
      )
      .then(response => {
        const status = response?.status
        if (d.enabled) {
          d(`request: SUCCESS (${status}) in ${getRequestDuration()}ms for ${urlWithParams}`)
        }
        return response
      })
      .catch(error => {
        const status = error?.response?.status
        if (silent) {
          if (d.enabled) {
            d('request <', status, urlWithParams)
          }
        } else {
          console.error('request <', status, urlWithParams)
        }
        if (
          status === GATEWAY_TIMEOUT ||
          status === REQUEST_TIMEOUT ||
          error?.code === 'ECONNABORTED' ||
          axios.isCancel(error)
        )
          throw new TimeoutError(error, { time: getRequestDuration() })
        if (status >= 500) throw new ServerError(error)
        throw error
      })
      .finally(() => requestClose())
  }

  static put(service, config) {
    return doRequest('PUT')(service, config)
  }

  static post(service, config) {
    return doRequest('POST')(service, config)
  }

  static delete(service, config) {
    return doRequest('DELETE')(service, config)
  }
}
