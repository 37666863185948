import { MARKERS_Z_INDEX } from '../../domain/map/markers/MarkerConstants'
import { getFutureDateISO } from '../../domain/utils/date'
import { getImageUrl } from '../../domain/utils/image'
import { transformElementToArray } from '../../utils/array'
import { getPoiFuelByFuelId, getPoiPriceLabel, getPoiRubricId } from './geoentity.accessors'

const FUEL_UNAVAILABLE_LABEL = 'Rupture'

export const getMarkerMainInformations = ({ poi, isCurrent, isFocus }) => {
  const { roundBrandIconUrl, rubric = {} } = poi
  const { id: rubricId, icon: rubricIcon, color } = rubric
  const icon = rubricIcon || rubricId
  const src = getImageUrl(roundBrandIconUrl)

  const zIndex = isFocus
    ? MARKERS_Z_INDEX.focus
    : isCurrent
      ? MARKERS_Z_INDEX.current
      : src
        ? MARKERS_Z_INDEX.brandedSmall
        : MARKERS_Z_INDEX.genericSmall

  return {
    zIndex,
    poi,
    ...(src ? { src } : { icon, color })
  }
}

export const getColorFromOpeningStatus = status => {
  switch (status) {
    case 'closed':
      return '#F44225'
    case 'closed_open_today':
      return '#FF9000'
    default:
      return '#03AC46'
  }
}

export const getFuelLabelFromId = fuelId => {
  switch (fuelId) {
    case 'E5_SP98':
      return 'SP 98'
    case 'E5_SP95':
      return 'SP 95'
    case 'E10':
      return 'SP95-E10'
    case 'E85':
      return 'E 85'
    case 'LPG':
      return 'GPL'
    default:
      return 'Gazole'
  }
}

export const isStationServiceRubric = rubricIds => transformElementToArray(rubricIds).includes('station-service')

export const getFuelPriceDisplayValue = matchingFuel => {
  const { available, price } = matchingFuel

  if (available) {
    if (price) return `${price} €`

    return '-'
  }

  return FUEL_UNAVAILABLE_LABEL
}

export const getHotelFutureDates = dates => ({
  from: dates?.from ?? getFutureDateISO({ days: 7 }),
  to: dates?.to ?? getFutureDateISO({ days: 8 })
})

export const getPoiFuelPriceDataForMarker = (poi, fuelId, fuelColor) => {
  const poiFuelInfo = getPoiFuelByFuelId(poi, fuelId)

  const fuelAvailability = poiFuelInfo?.available
  const fuelPrice = fuelAvailability
    ? poiFuelInfo?.price
      ? `${poiFuelInfo.price} €`
      : undefined
    : fuelAvailability === false
      ? FUEL_UNAVAILABLE_LABEL
      : undefined

  if (!fuelPrice) return

  return {
    price: fuelPrice,
    priceColor: fuelColor
  }
}

const hotelRubrics = ['hotel', 'chambre-d-hotes', 'location-gite', 'camping', 'auberge-de-jeunesse']

export const getPoiPriceDataForMarker = (poi, fuelId, fuelColor) => {
  if (isStationServiceRubric(getPoiRubricId(poi))) return getPoiFuelPriceDataForMarker(poi, fuelId, fuelColor)
  const price = getPoiPriceLabel(poi)
  return price && hotelRubrics.includes(getPoiRubricId(poi)) ? { price } : undefined
}
