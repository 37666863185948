import { ONE_MINUTE_MS } from '../../domain/utils/date'
import { invert } from '../../utils/object'

export const STEP_IDS = {
  departure: 'departure',
  arrival: 'arrival'
}

export const SET_STEP_FROM = {
  searchForm: 'searchForm',
  mapAction: 'mapAction'
}

export const SORT_TYPES = {
  duration: 'duration',
  price: 'price',
  co2: 'co2'
}

export const SEO_MODE_CAR = 'voiture'
export const SEO_MODE_WALK = 'pieton'
export const SEO_MODE_BIKE = 'velo'
export const SEO_MODE_TC = 'transports-en-commun'
export const SEO_MODE_TRAIN = 'train'
export const SEO_MODE_BUS = 'bus'
export const SEO_MODE_CARPOOL = 'covoiturage'
export const SEO_MODE_PLANE = 'avion'
export const SEO_MODE_MOTORBIKE = 'moto-scooter'
export const SEO_MODE_SCOOTER = 'trottinette'

export const MULTIPATH_MODE_CAR = 'voiture'
export const MULTIPATH_MODE_WALK = 'pieton'
export const MULTIPATH_MODE_BIKE = 'velo'
export const MULTIPATH_MODE_TC = 'tc'
export const MULTIPATH_MODE_TRAIN = 'train'
export const MULTIPATH_MODE_BUS = 'autocar'
export const MULTIPATH_MODE_CARPOOL = 'covoiturage'
export const MULTIPATH_MODE_PLANE = 'avion'
export const MULTIPATH_MODE_MOTORBIKE = 'motorbike'
export const MULTIPATH_MODE_SCOOTER = 'trottinette'
export const MULTIPATH_MODE_TAXI = 'taxi'

export const PROVIDER_WALK = 'walk'
export const PROVIDER_PERSONAL_BIKE = 'bike'
export const PROVIDER_SHARED_BIKE = 'bss'
export const PROVIDER_PERSONAL_SCOOTER = 'trottinette'
export const PROVIDER_TC = 'tc'
export const PROVIDER_TRAIN = 'train'
export const PROVIDER_PERSONAL_CAR = 'car'
export const PROVIDER_PERSONAL_MOTORBIKE = 'motorbike'
export const PROVIDER_BLABLACAR = 'blablacar'
export const PROVIDER_TICTACTRIP = 'tictactrip'
export const PROVIDER_BLABLACARBUS = 'blablacarbus'
export const PROVIDER_FLIXBUS = 'flixbus'
export const PROVIDER_DRIVEANDPARK = 'driveandpark'
export const PROVIDER_MARCEL = 'marcel'
export const PROVIDER_HEETCH = 'heetch'
export const PROVIDER_DOTT_VAE = 'dott_vae'

export const MULTIPATH_MODES = [
  MULTIPATH_MODE_CAR,
  MULTIPATH_MODE_MOTORBIKE,
  MULTIPATH_MODE_WALK,
  MULTIPATH_MODE_BIKE,
  MULTIPATH_MODE_TC,
  MULTIPATH_MODE_TRAIN,
  MULTIPATH_MODE_BUS,
  MULTIPATH_MODE_CARPOOL,
  MULTIPATH_MODE_PLANE,
  MULTIPATH_MODE_SCOOTER
]

export const SEO_MODES_TO_MULTIPATH_MODES = {
  [SEO_MODE_WALK]: MULTIPATH_MODE_WALK,
  [SEO_MODE_BIKE]: MULTIPATH_MODE_BIKE,
  [SEO_MODE_TC]: MULTIPATH_MODE_TC,
  [SEO_MODE_TRAIN]: MULTIPATH_MODE_TRAIN,
  [SEO_MODE_BUS]: MULTIPATH_MODE_BUS,
  [SEO_MODE_CARPOOL]: MULTIPATH_MODE_CARPOOL,
  [SEO_MODE_PLANE]: MULTIPATH_MODE_PLANE,
  [SEO_MODE_MOTORBIKE]: MULTIPATH_MODE_MOTORBIKE,
  [SEO_MODE_SCOOTER]: MULTIPATH_MODE_SCOOTER
}

export const SEO_PROVIDERS_PER_MODE = {
  [MULTIPATH_MODE_WALK]: [PROVIDER_WALK],
  [MULTIPATH_MODE_BIKE]: [PROVIDER_PERSONAL_BIKE, PROVIDER_DOTT_VAE, PROVIDER_SHARED_BIKE],
  [MULTIPATH_MODE_TC]: [PROVIDER_TC],
  [MULTIPATH_MODE_TRAIN]: [PROVIDER_TRAIN, PROVIDER_TICTACTRIP],
  [MULTIPATH_MODE_BUS]: [PROVIDER_BLABLACARBUS, PROVIDER_FLIXBUS],
  [MULTIPATH_MODE_CARPOOL]: [PROVIDER_BLABLACAR],
  [MULTIPATH_MODE_CAR]: [PROVIDER_PERSONAL_CAR],
  [MULTIPATH_MODE_PLANE]: [] // Plane necessary here to respond 200
}

export const MULTIPATH_MODES_TO_SEO_MODES = invert(SEO_MODES_TO_MULTIPATH_MODES)

export const PROVIDERS_PRIORITY_LIST = [
  { mode: MULTIPATH_MODE_WALK },
  { mode: MULTIPATH_MODE_BIKE, provider: PROVIDER_PERSONAL_BIKE },
  { mode: MULTIPATH_MODE_SCOOTER, provider: PROVIDER_PERSONAL_SCOOTER },
  { mode: MULTIPATH_MODE_BIKE },
  { mode: MULTIPATH_MODE_SCOOTER },
  { mode: MULTIPATH_MODE_MOTORBIKE },
  { mode: MULTIPATH_MODE_TC },
  { mode: MULTIPATH_MODE_TRAIN },
  { mode: MULTIPATH_MODE_BUS },
  { mode: MULTIPATH_MODE_CAR, provider: PROVIDER_DRIVEANDPARK },
  { mode: MULTIPATH_MODE_CARPOOL },
  { mode: MULTIPATH_MODE_TAXI },
  { mode: MULTIPATH_MODE_MOTORBIKE, provider: PROVIDER_PERSONAL_MOTORBIKE },
  { mode: MULTIPATH_MODE_CAR },
  { mode: MULTIPATH_MODE_PLANE }
]

export const MAX_TIME_BEFORE_ITINERARY_RECOMPUTE = 5 * ONE_MINUTE_MS

export const MODES_WITH_ALL_POLYLINES = [
  MULTIPATH_MODE_CAR,
  MULTIPATH_MODE_MOTORBIKE,
  MULTIPATH_MODE_WALK,
  MULTIPATH_MODE_BIKE,
  MULTIPATH_MODE_TC,
  MULTIPATH_MODE_SCOOTER
]
