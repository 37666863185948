export const buildCityQuestion = city => ({
  data: city,
  answer: {
    guessCoordinates: null,
    distance: 0,
    points: 0,
    bonus: false
  }
})

export const shuffle = items => {
  const itemsCopy = items.slice(0)
  const count = itemsCopy.length
  for (let i = 0; i < count; i++) {
    const rndIdx = Math.floor(Math.random() * count)
    const item = itemsCopy[i]
    itemsCopy[i] = itemsCopy[rndIdx]
    itemsCopy[rndIdx] = item
  }
  return itemsCopy
}

export const perfectAnswerConfettiSettings = {
  particleCount: 250,
  spread: 360,
  zIndex: 1000,
  origin: {
    x: 0.5,
    y: 0.5
  },
  shapes: ['star'],
  colors: ['FFE400', 'FFBD00', 'E89400', 'FFCA6C', 'FDFFB8']
}

export const endGameConfettiSettings = shape => {
  return {
    startVelocity: 30,
    spread: 360,
    ticks: 150,
    zIndex: 1000,
    scalar: '2',
    shapes: [shape],
    colors: ['#00d857', '#03ac46', '#99de00']
  }
}
