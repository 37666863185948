import debug from 'debug'
import { intersection } from '../../utils/array'
import { deleteEntriesFromLocalStorage } from '../../utils/browserStorageService'
import {
  COOKIE_DIDOMI_NAME,
  COOKIE_TCF_NAME,
  COOKIE_UTIQ_NAME,
  deleteCookies,
  getMainCookieDomain
} from '../utils/cookie'

const d = debug('CMP')

export class CMPService {
  constructor(didomiSDK) {
    this.didomiSDK = didomiSDK
    this.onConsentCallbacks = []
  }

  static apiKey = '003a8ab9-d71e-466e-8ba7-4a4e0ae36a46'

  static noticeId = 'qdjf2zbA'

  static StandardPurposes = {
    Cookies: 'cookies',
    SelectBasicAds: 'select_basic_ads',
    CreateAdsProfile: 'create_ads_profile',
    SelectPersonalizedAds: 'select_personalized_ads',
    MeasureAdPerformance: 'measure_ad_performance'
    /* Unused (for now) purposes :
    MarketResearch: 'market_research',
    MeasureContentPerformance: 'measure_content_performance',
    ImproveProducts: 'improve_products',
    CreateContentProfile: 'create_content_profile',
    SelectPersonalizedContent: 'select_personalized_content',
    GeolocationData: 'geolocation_data',
    DeviceCharacteristics: 'device_characteristics'
    */
  }

  static CustomPurposes = {
    PushNotifications: 'personnali-JGjwg3gy',
    Analytics: 'mesureda-b9AFF6XD',
    LiveRamp: 'personnali-n6LkBEKw',
    Utiq: 'utiq-iX4kPm2N',
    Booking: 'contextual-8Cng6EMQ'
  }

  static CustomVendors = {
    Mappy: 'c:mappy-ZEsPmiLa',
    MappyNativeAds: 'c:mappyvde-yAgyyyBi',
    Utiq: 'c:utiq-Gx2xdQAa'
  }

  static vendors = {
    Sentry: 'sentry',
    Booking: 'Hyb3oS0az'
  }

  get version() {
    return this.didomiSDK?.version
  }

  registerConsents(callback) {
    if (this.didomiSDK) {
      callback(this.getConsents())
    } else {
      this.onConsentCallbacks.push(callback)
    }
  }

  setSDK(didomiSDK) {
    this.didomiSDK = didomiSDK
    this.onConsentCallbacks.forEach(cb => cb(this.getConsents()))
  }

  isVendorEnabled(vendorId) {
    if (!this.didomiSDK) return false

    const enabledVendors = this.didomiSDK.getUserConsentStatusForAll().vendors.enabled || []
    return enabledVendors.includes(vendorId)
  }

  arePurposesEnabled(...purposesId) {
    if (!this.didomiSDK) return false

    const enabledPurposes = this.didomiSDK.getUserConsentStatusForAll().purposes.enabled || []
    return intersection(enabledPurposes, [...purposesId]).length === purposesId.length
  }

  hasUserConsentedForVendorAndItsPurpose(vendorId) {
    if (!this.didomiSDK) return false

    return this.didomiSDK.getCurrentUserStatus().vendors[vendorId]?.enabled ?? false
  }

  areAllVendorsAndPurposesDisabled() {
    if (!this.didomiSDK) return false

    const status = this.didomiSDK.getUserStatus()

    const enabledVendorsAndPurposesCount =
      status.vendors.consent.enabled.length + status.purposes.consent.enabled.length
    const disabledVendorsAndPurposesCount =
      status.vendors.consent.disabled.length + status.purposes.consent.disabled.length

    return enabledVendorsAndPurposesCount === 0 && disabledVendorsAndPurposesCount > 0
  }

  /**
   * Please keep in mind that the default cookies names can be changed:
   * https://developers.didomi.io/cmp/web-sdk/consent-notice/cookies#cookie-name
   */
  static cookiesToKeep = [COOKIE_DIDOMI_NAME, COOKIE_TCF_NAME, COOKIE_UTIQ_NAME]

  onConsentChanged() {
    if (this.areAllVendorsAndPurposesDisabled()) {
      console.info('consent has been declined, cleaning cookies and localStorage !')

      const domain = location.host
      deleteCookies(domain, CMPService.cookiesToKeep) // remove cookies on fr.mappy.com
      deleteCookies(`.${domain}`, CMPService.cookiesToKeep) // remove cookies on .fr.mappy.com (yes, I’m speaking to you cto_bundle)
      const domainWithoutSubdomain = getMainCookieDomain(domain)
      deleteCookies(domainWithoutSubdomain, CMPService.cookiesToKeep) // remove cookies on .mappy.com

      deleteEntriesFromLocalStorage()

      window.location.reload()
    }
  }

  getConsents() {
    const c = {
      tracking:
        this.isVendorEnabled(CMPService.CustomVendors.Mappy) &&
        this.arePurposesEnabled(CMPService.CustomPurposes.Analytics),

      push_notifications:
        this.isVendorEnabled(CMPService.CustomVendors.Mappy) &&
        this.arePurposesEnabled(CMPService.CustomPurposes.PushNotifications),

      native_advertising:
        this.isVendorEnabled(CMPService.CustomVendors.MappyNativeAds) &&
        this.arePurposesEnabled(
          CMPService.StandardPurposes.Cookies,
          CMPService.StandardPurposes.MeasureAdPerformance,
          CMPService.StandardPurposes.SelectBasicAds,
          CMPService.StandardPurposes.SelectPersonalizedAds
        ),

      send_keywords: this.arePurposesEnabled(
        CMPService.StandardPurposes.Cookies,
        CMPService.StandardPurposes.CreateAdsProfile,
        CMPService.StandardPurposes.SelectPersonalizedAds
      ),

      booking:
        this.arePurposesEnabled(CMPService.CustomPurposes.Booking) &&
        this.isVendorEnabled(CMPService.CustomVendors.Mappy) &&
        this.hasUserConsentedForVendorAndItsPurpose(CMPService.vendors.Booking),

      sentry: this.hasUserConsentedForVendorAndItsPurpose(CMPService.vendors.Sentry)
    }

    if (d.enabled) {
      d('getConsents:', c)
    }

    return c
  }

  open() {
    if (this.didomiSDK) {
      this.didomiSDK.preferences.show()
    }
  }

  getConsentString() {
    return new Promise(resolve => global.__tcfapi('getTCData', null, result => resolve(result.tcString)))
  }
}

export default new CMPService()
