import { reorderTrainRoute } from '../../domain/itinerary/specialcases/itinerary.specialcases.utils'
import { MULTIPATH_MODE_TRAIN } from './itinerary.constants'

let routeNextId = 1

export const resetForUnitTest = () => {
  routeNextId = 1
}

// Ideally, we would use a routeId sent by server, not build one ourselve...
export const decorateRouteWithId = route => ({
  routeId: routeNextId++,
  ...route
})

export const decorateRouteWithMode = mode => route => ({
  mode,
  ...route
})

export const removePolylinesOnServer = route => {
  if (__SERVER__ && route) {
    return {
      ...route,
      sections: []
    }
  }
  return route
}

export const extractRoutes = (response, mode) => {
  const routes = (response?.data?.routes ?? [])
    .map(decorateRouteWithId)
    .map(decorateRouteWithMode(mode))
    .map(removePolylinesOnServer)

  if (MULTIPATH_MODE_TRAIN === mode) return reorderTrainRoute(routes)

  return routes
}

export const extractColors = data => data?.colors

export const removePolylineDefinitionsOnServer = data => {
  if (__SERVER__ && data) {
    return {
      ...data,
      sections: data.sections.map(section => ({ ...section, polyline_definition: undefined }))
    }
  }
  return data
}

export const extractRoadbook = response => removePolylineDefinitionsOnServer(response?.data)
