import { selectCurrentHistoryRoute } from '../history/history.selectors'
import { pushHistory } from '../history/historySlice'
import { loadPageAdConfig, requestPageAdConfig, searchGeoentitiesForVde } from './ad.actions'
import { selectIsPageAdConfigRequested } from './ad.selectors'

import { setAdData } from './adSlice'

export const onPageAdConfigRequested =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    if (__BROWSER__ && selectIsPageAdConfigRequested(getState())) {
      loadPageAdConfig()(dispatch, getState)
    }

    return r
  }

/**
 * This middleware stands here as a workaround fix to cope with architecture complexity
 * related with usage of push instead of pop to control navigation back flow
 * which means onRouteEnter is never called on push-like-pop scenarii
 */

export const onPushLikePopRequestPageAdConfig =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    if (
      __BROWSER__ &&
      action?.type === pushHistory.type &&
      action?.payload?.routeOptions?.forceReplaceForPop === true
    ) {
      const route = selectCurrentHistoryRoute(getState())
      requestPageAdConfig(route)(dispatch, getState)
    }

    return r
  }

export const requestGeoentitiesForVde =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    if (action.type === setAdData.type) {
      const vdeData = action?.payload?.data?.vde
      if (vdeData) searchGeoentitiesForVde(vdeData)(dispatch, getState)
    }

    return r
  }

export default [onPageAdConfigRequested, onPushLikePopRequestPageAdConfig, requestGeoentitiesForVde]
