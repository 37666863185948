import {
  FAVORITE_TYPES,
  HOME_ICON,
  MAX_FAVORITES,
  OTHER_ICON,
  USER_ID_LEGACY,
  USER_ID_SUB,
  WORK_ICON
} from '../../domain/UserAccount/UserAccount.constants'
import { translate } from '../../domain/i18n/T'
import { ONE_DAY_MS } from '../../domain/utils/date'
import { createCustomSelector } from '../../domain/utils/react-reselect'
import { ROUTE_ADDRESS_HOME, ROUTE_HOME, ROUTE_ITINERARY_HOME } from '../../routes'
import { isEmpty } from '../../utils/lang'
import { getSelectedAddress } from '../address/address.selectors'
import { selectCurrentGeoentityId, selectGeoentityRubricId } from '../geoentity/geoentity.selectors'
import { selectCurrentHistoryRoute } from '../history/history.selectors'
import {
  selectUserAccountIncentiveItineraryResultsDisplayTimestamp,
  selectUserAccountIncentivePopinDisplayTimestamp
} from '../incentive/incentive.selectors'
import { selectItineraryComputedCount } from '../itinerary/itinerary.selectors'
import { selectIsMapFullScreen } from '../map/map.selectors'
import { selectIsFrenchSite, selectLocale, selectVisitCount } from '../navigation/navigation.selectors'
import { selectSearchTerms } from '../search/search.selectors'
import { isLabelMatchingInputValueKeyword, normalizeInputValueKeyword } from '../suggest/suggest.selectors.utils'

export const selectIsUserAccountEnabled = selectIsFrenchSite

export const selectUserAccountState = store => store?.userAccount

export const selectUserConnectedStatus = createCustomSelector(
  selectUserAccountState,
  userAccount => userAccount?.connected
)

export const selectIsUserConnected = createCustomSelector(selectUserConnectedStatus, connected => connected === true)

export const selectIsUserNotConnected = createCustomSelector(
  selectUserConnectedStatus,
  connected => connected === false
)

export const selectIsUserKnown = createCustomSelector(
  selectIsUserConnected,
  selectIsUserNotConnected,
  (connected, notConnected) => connected || notConnected
)

export const selectKeycloakToken = createCustomSelector(selectUserAccountState, userAccount => userAccount?.token)

export const selectUserIdentity = createCustomSelector(
  selectUserAccountState,
  userAccount => userAccount?.identity ?? {}
)

export const selectUserIdForTag = createCustomSelector(
  selectUserIdentity,
  (identity = {}) => identity[USER_ID_LEGACY] || identity[USER_ID_SUB]
)

export const selectUserPicture = createCustomSelector(selectUserIdentity, content => content?.picture)

export const selectUserEmail = createCustomSelector(selectUserIdentity, content => content?.email)

export const selectUserName = createCustomSelector(selectUserIdentity, content => content?.name ?? 'Anonyme')

export const selectUserFavorites = createCustomSelector(
  selectUserAccountState,
  selectIsUserAccountEnabled,
  (userAccount, userAccountAvailable) => {
    if (!userAccountAvailable || !userAccount?.favorites) return null

    const { home, work, others = [] } = userAccount.favorites

    return {
      home: { ...home, favIcon: HOME_ICON },
      work: { ...work, favIcon: WORK_ICON },
      others: others.map(other => ({ ...other, favIcon: OTHER_ICON }))
    }
  }
)

export const selectUserFavoritesFlatten = createCustomSelector(selectUserFavorites, favorites => {
  if (!favorites) return []
  const { home, work, others = [] } = favorites
  return [home, work, ...others]
})

export const selectUserFavoritesCount = createCustomSelector(
  selectUserFavoritesFlatten,
  (favorites = []) => favorites.length
)

export const selectUserFavoritesLimitNotReached = createCustomSelector(
  selectUserFavoritesCount,
  (count = 0) => count < MAX_FAVORITES
)

const formatSuggest = fav => {
  return { labels: [fav.label, fav.address], subtype: { id: fav.favIcon } }
}

export const selectUserFavoritesSuggest = createCustomSelector(
  selectUserFavorites,
  selectIsUserAccountEnabled,
  (favorites, selectIsUserAccountAvailable) => {
    if (!selectIsUserAccountAvailable) return null

    const { home, work, others } = favorites
    return [formatSuggest(home), formatSuggest(work), ...others.map(other => formatSuggest(other))]
  }
)

export const selectFavoritesFilteredBySearch = createCustomSelector(
  selectUserFavoritesSuggest,
  selectSearchTerms,
  (favorites, searchTerms) => {
    if (!favorites) return null
    if (searchTerms === '') return favorites

    return favorites.filter(favorite => {
      const currentSearchKeyword = normalizeInputValueKeyword(searchTerms)
      return isLabelMatchingInputValueKeyword(currentSearchKeyword, favorite.labels.join(' '))
    })
  }
)

export const selectFavoriteByCurrentPoiId = createCustomSelector(
  selectUserFavoritesFlatten,
  selectCurrentGeoentityId,
  (favorites, currentGeoentityId) => {
    if (isEmpty(favorites) || isEmpty(currentGeoentityId)) return undefined

    return favorites.find(({ poiId }) => poiId === currentGeoentityId)
  }
)

const simplifyStr = (s = '') => s.toLowerCase().replace(new RegExp('[·/_,.:;]', 'g'), '')

export const selectFavoriteBySelectedAddress = createCustomSelector(
  selectUserFavoritesFlatten,
  getSelectedAddress,
  (favorites, address) => {
    if (isEmpty(favorites) || isEmpty(address)) return undefined
    return favorites.find(({ address: favoriteAddress }) => simplifyStr(favoriteAddress) === simplifyStr(address.label))
  }
)

export const selectShouldDisplayFavoriteButtonForCurrentGeoentity = createCustomSelector(
  selectGeoentityRubricId,
  selectIsUserAccountEnabled,
  (rubricId, selectIsUserAccountAvailable) =>
    selectIsUserAccountAvailable &&
    !['velos-en-libre-service', 'scooter-en-libre-service', 'trottinette-en-libre-service'].includes(rubricId)
)

export const DELAY_BETWEEN_POPIN = ONE_DAY_MS * 7

const selectIsCurrentRouteEligibleForIncentivePopinDisplay = createCustomSelector(
  selectCurrentHistoryRoute,
  currentRoute => [ROUTE_HOME, ROUTE_ITINERARY_HOME, ROUTE_ADDRESS_HOME].includes(currentRoute)
)

export const selectShouldDisplayUserAccountIncentivePopin = createCustomSelector(
  selectIsUserAccountEnabled,
  selectUserAccountIncentivePopinDisplayTimestamp,
  selectVisitCount,
  selectIsCurrentRouteEligibleForIncentivePopinDisplay,
  (userAccountEnabled, lastDisplay, visitCount, isCurrentRouteEligible) => {
    const now = new Date().getTime()
    return userAccountEnabled && isCurrentRouteEligible && visitCount > 1 && now - lastDisplay > DELAY_BETWEEN_POPIN
  }
)

export const selectShouldDisplayUserAccountIncentiveItineraryResults = createCustomSelector(
  selectIsUserAccountEnabled,
  selectUserAccountIncentiveItineraryResultsDisplayTimestamp,
  selectItineraryComputedCount,
  selectIsMapFullScreen,
  (userAccountEnabled, lastDisplay, computedCount, fullscreen) => {
    const now = new Date().getTime()
    return userAccountEnabled && !fullscreen && computedCount > 1 && now - lastDisplay > DELAY_BETWEEN_POPIN
  }
)

export const selectFakeFavorites = createCustomSelector(selectLocale, locale => ({
  home: {
    incentive: true,
    labels: [
      translate(locale)('useraccount.favorite.home'),
      translate(locale)('useraccount.favorite.type-your-address')
    ],
    subtype: { id: HOME_ICON },
    type: FAVORITE_TYPES.HOME,
    uuid: 1,
    favIcon: HOME_ICON
  },
  work: {
    incentive: true,
    labels: [
      translate(locale)('useraccount.favorite.work'),
      translate(locale)('useraccount.favorite.type-your-address')
    ],
    subtype: { id: WORK_ICON },
    type: FAVORITE_TYPES.WORK,
    uuid: 2,
    favIcon: WORK_ICON
  }
}))
