import { isEmpty } from '../utils/lang'

export const DEVICE_BREAKPOINT = '(min-width: 999px)'

export const RELOAD_RESIZE_CONFIRM_MSG_FR =
  'La taille de la fenêtre a changé. Souhaitez-vous rafraîchir la page pour une expérience optimale ?'
export const RELOAD_RESIZE_CONFIRM_MSG_EN =
  'Window size has changed. Would you like to refresh the page for a better experience ?'

const computeScreenIsGE = () => global?.matchMedia?.(DEVICE_BREAKPOINT)?.matches ?? false

let screenIsGE = computeScreenIsGE()

export const isGE = () => screenIsGE

export const isPE = () => !screenIsGE

export const reloadIfNecessary = () => {
  const refreshMessage = global?.location?.hostname?.startsWith?.('fr')
    ? RELOAD_RESIZE_CONFIRM_MSG_FR
    : RELOAD_RESIZE_CONFIRM_MSG_EN

  if (computeScreenIsGE() !== screenIsGE && global.confirm(refreshMessage)) {
    global.location.reload()
  }
  screenIsGE = computeScreenIsGE()
}

let reloadTimeout

export const updateScreenSizeHandler = () => {
  clearTimeout(reloadTimeout)
  reloadTimeout = setTimeout(reloadIfNecessary, 1000)
}

export const withOnlyGE = Component => {
  if (!isGE()) return () => null

  return Component
}

export const withOnlyPE = Component => {
  if (isGE()) return () => null

  return Component
}

export const withOnlyBrowser = Component => {
  if (__BROWSER__) return Component
  else return () => null
}

export const getDevicePixelRatio = () => {
  const dpr = Math.floor(global.devicePixelRatio || 1)
  return Math.max(2, Math.min(dpr, 4))
}

export const hasTouchSupport = () => {
  // From http://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript/4819886#4819886
  return Boolean('ontouchstart' in window) || Boolean('msmaxtouchpoints' in window.navigator)
}

export const isAndroid = input => {
  const { userAgent } = input || navigator

  if (isEmpty(userAgent)) {
    throw new Error('Parameter userAgent missing')
  }

  return /android/i.test(userAgent)
}

export const isIos = input => {
  const { userAgent } = input || navigator

  if (isEmpty(userAgent)) {
    throw new Error('Parameter userAgent missing')
  }
  return /ipad/i.test(userAgent) || /iphone/i.test(userAgent)
}

export const isRetina = () => typeof window !== 'undefined' && window.devicePixelRatio > 1

const BOTS_KEYWORD_LIST = ['bingbot']

export const isBotNotWebGlCompliant = input => {
  const { userAgent } = input || navigator
  if (isEmpty(userAgent)) {
    throw new Error('Parameter userAgent missing')
  }
  return BOTS_KEYWORD_LIST.some(botKeyword => userAgent.includes(botKeyword))
}
