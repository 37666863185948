import { createCustomSelector } from '../../domain/utils/react-reselect'

const selectCMPState = store => store?.cmp

export const selectCMPConsent = createCustomSelector(selectCMPState, cmp => cmp?.consent)

export const selectHasUserConsentedForTracking = createCustomSelector(
  selectCMPConsent,
  consent => consent?.tracking ?? false
)

export const selectHasUserConsentedForNativeAdvertising = createCustomSelector(
  selectCMPConsent,
  consent => consent?.native_advertising ?? false
)

export const selectHasUserConsentedForPushNotifications = createCustomSelector(
  selectCMPConsent,
  consent => consent?.push_notifications ?? false
)

export const selectHasUserConsentedForSendKeywords = createCustomSelector(
  selectCMPConsent,
  consent => consent?.send_keywords ?? false
)
