import requestSearch from '../../dataSource/search/search.requests'
import AddressNotFoundError from '../../domain/error/AddressNotFoundError'
import NoResultError from '../../domain/error/NoResultError'
import SilentError from '../../domain/error/SilentError'
import { bboxArrayToBboxArrays, bboxToRouteParameter } from '../../domain/map/conversion'
import {
  loadAddressError,
  loadAddressRequest,
  loadMultiAddressSuccess,
  loadSingleAddressSuccess
} from '../address/addressSlice'
import { selectGeoentityFilterParameters } from '../geoentity/filter.selectors'
import { searchGeoentities } from '../geoentity/geoentity.actions'
import { selectSelectedGeoentityIdentityCard } from '../geoentity/geoentity.selectors'
import {
  resetGeoentityList,
  setGeoentitiesReceived,
  setGeoentitiesRequested,
  setGeoentityList
} from '../geoentity/geoentitySlice'
import {
  navigateToGeoentity,
  navigateToSearchByApp,
  navigateToSearchByQuery,
  navigateToSearchByRubric
} from '../history/history.actions'
import { setStepFromAddress } from '../itinerary/itinerary.actions'
import { selectMapActiveBbox, selectMapBbox } from '../map/map.selectors'
import { selectLocale } from '../navigation/navigation.selectors'
import { getGeoentityTypeDataForSearch } from '../poiassets/poiassets.selectors'
import { getSearchMaxPois, selectSearchParameters } from './search.selectors'
import { searchServiceCtx } from './search.service.constants'
import { setSearchTerms } from './searchSlice'

export const search =
  (params = {}) =>
  (dispatch, getState) => {
    const {
      searchFilterType = searchServiceCtx.filter.poiXorAddress,
      terms,
      options,
      setGeoentityListActionType = setGeoentityList.type,
      navigateAfterSearch = true,
      extendBbox = 1,
      ...args
    } = params

    dispatch(loadAddressRequest())
    dispatch(setGeoentitiesRequested())

    const state = getState()
    const bbox = selectMapBbox(state)
    const locale = selectLocale(state)

    return requestSearch({ terms, searchFilterType, extendBbox, bbox, locale, ...args })
      .then(({ addresses = [], pois = [], viewport, header = {} }) => {
        const havePois = pois.length > 0
        const haveAddresses = addresses.length > 0

        if (header.storeChainsId) {
          return navigateAfterSearch && navigateToSearchByApp(header.storeChainsId)(dispatch, getState)
        }

        if (header.rubricId) {
          return navigateAfterSearch && navigateToSearchByRubric(header.rubricId)(dispatch, getState)
        }

        if (havePois) {
          dispatch({
            type: setGeoentityListActionType,
            payload: {
              pois,
              viewport,
              extendBbox
            }
          })
          if (!haveAddresses) {
            if (pois.length === 1) {
              const { id } = pois[0]
              return navigateToGeoentity({ id })(dispatch, getState)
            }

            const options = { avoidSearching: true }
            if (header.located) options.bbox = bboxToRouteParameter(bboxArrayToBboxArrays(viewport))
            return navigateAfterSearch && navigateToSearchByQuery(terms, options)(dispatch, getState)
          }
        }

        if (!havePois && !haveAddresses) throw new AddressNotFoundError()

        dispatch({
          type: addresses.length === 1 ? loadSingleAddressSuccess.type : loadMultiAddressSuccess.type,
          payload: {
            addresses,
            viewport,
            options
          }
        })
      })
      .catch(e => {
        const error = e instanceof NoResultError ? new AddressNotFoundError() : e
        dispatch(resetGeoentityList())
        if (!(e instanceof SilentError)) {
          dispatch(loadAddressError({ error }))
        }

        throw error
      })
      .finally(() => dispatch(setGeoentitiesReceived()))
  }

export const searchItineraryLocation =
  (params = {}) =>
  (dispatch, getState) => {
    const { terms, options, ...args } = params

    dispatch(loadAddressRequest())

    const state = getState()
    const bbox = selectMapBbox(state)
    const locale = selectLocale(state)

    return requestSearch({ terms, searchFilterType: searchServiceCtx.filter.poiXorAddress, bbox, locale, ...args })
      .then(({ addresses = [], pois = [], viewport }) => {
        if (addresses.length === 0 && pois.length === 0) throw new AddressNotFoundError()

        const locations = addresses.length !== 0 ? addresses : pois

        if (addresses.length > 1) {
          dispatch(
            loadMultiAddressSuccess({
              addresses: locations,
              viewport,
              options
            })
          )
        } else {
          setStepFromAddress(locations[0])(dispatch, getState)
        }
      })
      .catch(e => {
        const error = e instanceof NoResultError ? new AddressNotFoundError() : e
        dispatch(loadAddressError({ error }))

        throw error
      })
  }

export const collectParameters = (parameters = {}) => {
  return Object.values(parameters).reduce((acc, cur) => {
    Object.entries(cur).forEach(([param, value]) => {
      if (acc[param]) {
        acc[param] = acc[param].concat(value)
      } else {
        acc[param] = [value]
      }
    })
    return acc
  }, {})
}

export const refreshGeoentities = bbox => (dispatch, getState) => {
  const state = getState()
  const { appOrRubricIds, searchTerms, biTagId } = getGeoentityTypeDataForSearch(state, selectSearchParameters(state))
  const filterParamsByFilter = collectParameters(selectGeoentityFilterParameters(state))

  if (appOrRubricIds) {
    return searchGeoentities({
      appOrRubricIds,
      filterParams: filterParamsByFilter,
      biTagId,
      max: getSearchMaxPois(),
      extendBbox: 0,
      noRequestedDispatch: true,
      bbox
    })(dispatch, getState)
  } else if (searchTerms) {
    return requestSearch({
      terms: searchTerms,
      searchFilterType: searchServiceCtx.filter.poi,
      bbox: selectMapActiveBbox(state),
      extendBbox: 0,
      locale: selectLocale(state)
    }).then(({ pois = [], viewport }) => {
      dispatch(
        setGeoentityList({
          pois,
          viewport
        })
      )
    })
  }
}
export const setSelectedGeoentityAsCurrentSearch = () => (dispatch, getState) => {
  const { name, address } = selectSelectedGeoentityIdentityCard(getState())
  dispatch(setSearchTerms(`${name} - ${address}`))
}
