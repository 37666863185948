import { findAddressforGeolocation, geocodeForward } from '../../dataSource/address/address.request'
import { selectLocale } from '../navigation/navigation.selectors'
import { loadAddressError, loadAddressRequest, loadSingleAddressComputeRadiusSuccess } from './addressSlice'

const handleError = dispatch => error => {
  dispatch(loadAddressError({ error }))

  throw error
}

export const updateCurrentSearchFromLngLat = lngLat => (dispatch, getState) => {
  const locale = selectLocale(getState())
  return findAddressforGeolocation({ ...lngLat, locale })
    .then(({ addresses, viewport }) => {
      dispatch(
        loadSingleAddressComputeRadiusSuccess({
          addresses,
          viewport
        })
      )
    })
    .catch(handleError(dispatch))
}

export const updateCurrentSearchFromAddress = data => dispatch => {
  dispatch(loadSingleAddressComputeRadiusSuccess(data))
}

export const searchSingleComputeRadiusAddress =
  ({ terms }) =>
  (dispatch, getState) => {
    dispatch(loadAddressRequest())

    const locale = selectLocale(getState())

    return (
      geocodeForward({ location: terms, locale })
        // eslint-disable-next-line sonarjs/no-identical-functions
        .then(({ addresses, viewport }) => {
          dispatch(
            loadSingleAddressComputeRadiusSuccess({
              addresses,
              viewport
            })
          )
        })
        .catch(handleError(dispatch))
    )
  }
