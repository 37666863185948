import { NA } from './analytics.constants'
import { replaceValues } from './analyticsUtils'
export const CLICK_TAG = {}

export const PAGE_ACTIVE = 'PageActive'
export const MODE_ID = 'IDMode'
export const PROVIDER_ID = 'provider'
export const DISTANCE = 'distance'
export const APP_ID = 'appId'
export const APP_OR_SUBCAT_ID = 'appOrSubCatId'
export const CAT_ID = 'catId'
export const SUGGEST_ORIGIN = 'suggestOrigin'
export const SUGGEST_DETAILS = 'suggestDetails'
export const SUGGEST_SUBDETAILS = 'suggestSubDetails'
export const SUGGEST_SUBSUBDETAILS = 'suggestSubSubDetails'
export const HISTORY = 'Historique'
export const SUGGESTION = 'Suggestion'
export const FAVORITE = 'Favori'
export const MYPOSITION = 'MaPosition'
export const ADDRESS = 'Adresse'
export const POI = 'POI'
export const RUBRIC = 'Rubrique'
export const STORE = 'Enseigne'
export const FILTER = 'Filter'
export const PLACEMENT = 'Placement'
export const TYPE = 'Type'
export const ACTION = 'Action'
export const SELECTION = 'Selection'
export const ANCRAGE = 'Ancrage'
export const ZIP = 'CodePostal'
export const ZIP_DEPARTURE = 'CodePostalDepart'
export const ZIP_ARRIVAL = 'CodePostalArrivee'
export const POI_DEPARTURE_TYPE = 'TypeDepart'
export const POI_ARRIVAL_TYPE = 'TypeArrivee'
export const ROUTE_LABEL = 'ResultatClique'
export const QID = 'Qid'
export const NUMBER_OF_DISPLAYED_ROUTES_RESULTS = 'NombreResultats'
export const ROUTE_POSITION_CLICK = 'PositionResultatClique'
export const ROUTE_DURATION = 'DureeResultatClique'
export const ROUTE_LENGTH = 'DistanceResultatClique'
export const ROUTE_PRICE = 'TarifResultatClique'
export const ROUTE_RESULTS_LABELS = 'LibelleResultats'
export const ROUTE_RESULTS_DURATIONS = 'DureeResultats'
export const ROUTE_RESULTS_LENGTHS = 'DistanceResultats'
export const ROUTE_RESULTS_PRICES = 'TarifResultats'
export const ROUTE_RESULTS_ACTIVE_SORT = 'TriResultats'
export const POI_TYPE = 'PoiType'
export const POI_POSITION = 'PoiPosition'

const TYPE_ACTION = 'action'

CLICK_TAG.XTC_ITINERARY_TIMEOUT = {
  level2: 2,
  chapter1: 'RechercheItineraire',
  chapter2: NA,
  chapter3: NA,
  name: `#${PAGE_ACTIVE}:TimeOut_RecalculItineraire`
}

CLICK_TAG.XTC_VIEWMODE = {
  level2: 2,
  chapter1: 'Vue',
  chapter2: '#ModeAffichage',
  chapter3: NA,
  name: `#${PAGE_ACTIVE}:${NA}`
}

CLICK_TAG.XTC_CLOSE_AD = {
  level2: 2,
  chapter1: 'PUB',
  chapter2: 'Habillage',
  chapter3: NA,
  name: `#${PAGE_ACTIVE}:Masque`
}

CLICK_TAG.XTC_ITINERARY_PRINT_SIMPLE = {
  level2: 2,
  chapter1: 'FeuilleDeRoute',
  chapter2: NA,
  chapter3: NA,
  name: `#${PAGE_ACTIVE}:ImpressionSimplifie`
}

CLICK_TAG.XTC_ITINERARY_NDF = {
  level2: 2,
  chapter1: 'FeuilleDeRoute',
  chapter2: NA,
  chapter3: NA,
  name: `#${PAGE_ACTIVE}:NDF`
}

CLICK_TAG.XTC_FOOTER_AUTO_PROMO_APPS = {
  level2: 2,
  chapter1: 'Footer',
  chapter2: '#appstore',
  chapter3: NA,
  name: `#${PAGE_ACTIVE}:TelechargerApplication`
}

CLICK_TAG.XTC_APPLICATION_TELECHARGER = {
  level2: 2,
  chapter1: 'Application',
  chapter2: '#appstore',
  chapter3: '#area',
  name: `#${PAGE_ACTIVE}:TelechargerApplication`
}

CLICK_TAG.XTC_FOOTER_RETROUVEZMAPPY = {
  level2: 2,
  chapter1: 'Footer',
  chapter2: 'Retrouvez_Mappy_sur',
  chapter3: NA,
  name: `#${PAGE_ACTIVE}:#target`
}
CLICK_TAG.XTC_VDE_PUSH = {
  level2: 2,
  chapter1: 'Recherche',
  chapter2: `#${CAT_ID}`,
  chapter3: `#${APP_ID}`,
  name: `#${PAGE_ACTIVE}:#tagVdeType`
}
CLICK_TAG.XTC_VDE_PUSH_CLOSED = {
  level2: 2,
  chapter1: 'Recherche',
  chapter2: `#${CAT_ID}`,
  chapter3: `#${APP_ID}`,
  name: `#${PAGE_ACTIVE}:#tagVdeType:Fermeture`
}
CLICK_TAG.XTC_DETAIL_LISTMAP_POI = {
  level2: 2,
  chapter1: 'Fiche',
  chapter2: `#${CAT_ID}`,
  chapter3: `#${APP_ID}`,
  name: `#${PAGE_ACTIVE}:#affichage`
}

CLICK_TAG.XTC_GOTO = {
  level2: 2,
  chapter1: 'Yaller',
  chapter2: `#ancrage`,
  chapter3: `#${CAT_ID}`,
  name: `#${PAGE_ACTIVE}:Yaller`
}
CLICK_TAG.XTC_GOTO_AJOUT_ETAPE = {
  level2: 2,
  chapter1: 'Yaller',
  chapter2: `#ancrage`,
  chapter3: `#${CAT_ID}`,
  name: `#${PAGE_ACTIVE}:AjoutEtape`
}

CLICK_TAG.XTC_SEARCH_FORM = {
  level2: 1,
  chapter1: 'RechercheMoteur',
  chapter2: 'Formulaire',
  chapter3: `#${SUGGEST_ORIGIN}`,
  name: `#${PAGE_ACTIVE}:#${SUGGEST_DETAILS}:#${SUGGEST_SUBDETAILS}`
}

CLICK_TAG.XTC_SEARCH_FORM_HOME_WORK = {
  level2: 1,
  chapter1: 'RechercheMoteur',
  chapter2: 'Formulaire',
  chapter3: `#${SUGGEST_ORIGIN}`,
  name: `#${PAGE_ACTIVE}:#${SUGGEST_DETAILS}:#${SUGGEST_SUBDETAILS}:#${SUGGEST_SUBSUBDETAILS}`
}

CLICK_TAG.XTC_MULTIPATH_RESULTS_SLIDER = {
  level2: 2,
  chapter1: 'RechercheItineraire',
  chapter2: 'menusuperieur',
  chapter3: `#${MODE_ID}_#${PROVIDER_ID}`,
  name: `#${PAGE_ACTIVE}:ClicListeResultats`
}

CLICK_TAG.XTC_CARTE_LOC = {
  level2: 2,
  chapter1: 'Carte',
  chapter2: NA,
  chapter3: NA,
  name: `#${PAGE_ACTIVE}:Geoloc`
}

CLICK_TAG.XTC_DRAG_AND_DROP = {
  level2: 2,
  chapter1: 'Drag_and_drop',
  chapter2: NA,
  chapter3: NA,
  name: `#${PAGE_ACTIVE}:#${ACTION}`
}

CLICK_TAG.XTC_ITINERARY_RETURN = {
  level2: 2,
  chapter1: 'Back',
  chapter2: NA,
  chapter3: NA,
  name: `#${PAGE_ACTIVE}:Back`
}
CLICK_TAG.XTC_CATEGORY_SURMAROUTE = {
  level2: 2,
  chapter1: 'Recherche',
  chapter2: `#${CAT_ID}`,
  chapter3: `#${APP_OR_SUBCAT_ID}`,
  name: `#${PAGE_ACTIVE}:surmaroute:#${MODE_ID}:#${DISTANCE}`
}

CLICK_TAG.XTC_ANCRAGE_HEADER = {
  level2: 2,
  chapter1: 'AncrageHeader',
  chapter2: NA,
  chapter3: NA,
  name: '#NomAncrage'
}

CLICK_TAG.XTC_LANDING_ITINERARY_GOTO_MP = {
  level2: 2,
  chapter1: 'Itineraire',
  chapter2: `#MotCle`,
  chapter3: `#${MODE_ID}`,
  name: `#${PAGE_ACTIVE}:Champ#type`
}

CLICK_TAG.XTC_LANDING_ACTIVITE_VILLE_GOTO_POI = {
  level2: 2,
  chapter1: 'Fiche',
  chapter2: `#${RUBRIC}`,
  chapter3: `#${ZIP}`,
  name: `#${PAGE_ACTIVE}:Champ#type` // bug NA
}

CLICK_TAG.XTC_LANDING_ITINERARY_AFFICHER_SUITE = {
  level2: 2,
  chapter1: 'Itineraire',
  chapter2: NA,
  chapter3: `#${MODE_ID}`,
  name: `#${PAGE_ACTIVE}:afficherlasuite`
}

CLICK_TAG.XTC_CATEGORY_POPIN = {
  level2: 2,
  chapter1: 'Recherche',
  chapter2: `#${CAT_ID}`,
  chapter3: `#${APP_ID}`,
  name: `#${PAGE_ACTIVE}:popin`
}

CLICK_TAG.XTC_CATEGORY_LIST = {
  level2: 2,
  chapter1: 'Fiche',
  chapter2: `#${CAT_ID}`,
  chapter3: `#${APP_OR_SUBCAT_ID}`,
  name: `#${PAGE_ACTIVE}:Liste`
}

CLICK_TAG.XTC_IMMERSIVE = {
  level2: 2,
  chapter1: 'Vue',
  chapter2: 'ActiverImmersive',
  chapter3: NA,
  name: `#${PAGE_ACTIVE}:#${ANCRAGE}`
}

CLICK_TAG.XTC_CLICK_MY_MAP = {
  level2: 2,
  chapter1: 'Carte',
  chapter2: `#${PAGE_ACTIVE}`,
  chapter3: `#${CAT_ID}`,
  name: 'Clic_my_map'
}

CLICK_TAG.XTC_BLOC_HOTEL = {
  chapter1: 'Fiche',
  chapter2: NA,
  chapter3: NA,
  name: `#${PAGE_ACTIVE}:BlocHotel`
}

CLICK_TAG.XTC_DETAIL_FICHE_ADRESSE = {
  level2: 2,
  chapter1: 'Fiche',
  chapter2: `#${CAT_ID}`,
  chapter3: `#${APP_ID}`,
  name: `#${PAGE_ACTIVE}:Liste`
}
CLICK_TAG.XTC_SHARE = {
  level2: 2,
  chapter1: 'Partage',
  chapter2: NA,
  chapter3: NA,
  name: `#${PAGE_ACTIVE}:Partage`
}
CLICK_TAG.XTC_CATEGORY_MORE = {
  level2: 2,
  chapter1: 'Fiche',
  chapter2: `#${CAT_ID}`,
  chapter3: `#${APP_ID}`,
  name: `#${PAGE_ACTIVE}:Plus`
}
CLICK_TAG.XTC_CATEGORY_BAR_CATEGORY = {
  level2: 2,
  chapter1: 'Recherche',
  chapter2: `#${CAT_ID}`,
  chapter3: `#${APP_OR_SUBCAT_ID}`,
  name: `#${PAGE_ACTIVE}:BarreCategorie`
}

CLICK_TAG.XTC_CATEGORY_FILTER = {
  chapter1: 'Recherche',
  chapter2: `#${CAT_ID}`,
  chapter3: `#${APP_OR_SUBCAT_ID}`,
  name: `#${PAGE_ACTIVE}:#${PLACEMENT}:#${FILTER}`
}
CLICK_TAG.XTC_CAMPAGNE_PUSH = {
  chapter1: 'PushCampagneSponso',
  chapter2: NA,
  chapter3: NA,
  name: '#url'
}

CLICK_TAG.XTC_COMPTE_PERSO_BOUTON_BURGER = {
  chapter1: 'ComptePerso',
  chapter2: NA,
  chapter3: NA,
  name: `#${PAGE_ACTIVE}:BoutonBurger`
}
CLICK_TAG.XTC_COMPTE_PERSO_NON_CONNECTE = {
  chapter1: 'ComptePerso',
  chapter2: NA,
  chapter3: NA,
  name: `#${PAGE_ACTIVE}:BoutonConnexionInscription`
}
CLICK_TAG.XTC_COMPTE_PERSO_GERER_MON_COMPTE = {
  chapter1: 'ComptePerso',
  chapter2: NA,
  chapter3: NA,
  name: `AccueilMonCompte:gererMonCompte`
}
CLICK_TAG.XTC_SE_DECONNECTER = {
  chapter1: 'ComptePerso',
  chapter2: NA,
  chapter3: NA,
  name: `AccueilMonCompte:SeDeconnecter`
}
CLICK_TAG.XTC_COMPTE_PERSO_LIEUX_FAVORIS_LISTE = {
  chapter1: 'ComptePerso',
  chapter2: NA,
  chapter3: `#${TYPE}`,
  name: `MesLieuxFavoris:#${ACTION}`
}
CLICK_TAG.XTC_COMPTE_PERSO_LIEUX_FAVORIS_FICHE = {
  chapter1: 'ComptePerso',
  chapter2: NA,
  chapter3: NA,
  name: `#${PAGE_ACTIVE}:#${ACTION}`
}
CLICK_TAG.XTC_CHOIX_MODEFAVORI = {
  chapter1: 'Options',
  chapter2: NA,
  chapter3: `#${SELECTION}`,
  name: `#${PAGE_ACTIVE}:ModeFavori`
}
CLICK_TAG.XTC_CHOIX_VEHICULE = {
  chapter1: 'Options',
  chapter2: NA,
  chapter3: `#${SELECTION}`,
  name: `#${PAGE_ACTIVE}:VehiculeFavori`
}
CLICK_TAG.XTC_CHOIX_DEUXROUES = {
  chapter1: 'Options',
  chapter2: NA,
  chapter3: `#${SELECTION}`,
  name: `#${PAGE_ACTIVE}:DeuxRouesFavori`
}
CLICK_TAG.XTC_MENU_CONTEXTUEL = {
  chapter1: 'Menu_contextuel',
  chapter2: NA,
  chapter3: NA,
  name: `#${PAGE_ACTIVE}:#${ACTION}`
}
CLICK_TAG.XTC_CARTE_HD_OFF = {
  chapter1: 'Carte',
  chapter2: NA,
  chapter3: NA,
  name: `#${PAGE_ACTIVE}:#${ACTION}`
}
CLICK_TAG.XTC_CHEVRON_BANDEAU_LATERAL = {
  chapter1: 'Carte',
  chapter2: 'BandeauLateral',
  chapter3: 'Chevron',
  name: `#${PAGE_ACTIVE}:#${ACTION}`
}

export const resolveClickTag = ({ clickTag, store, ...params }) => {
  return {
    type: TYPE_ACTION,
    ...replaceValues(clickTag, params)
  }
}
