export const ADDRESS_BIG_MARKER_HEIGHT = 60
export const ADDRESS_BIG_MARKER_WIDTH = 52
export const POI_BIG_MARKER_HEIGHT = 39
export const POI_BIG_MARKER_WIDTH = 36

export const MARKERS_Z_INDEX = {
  focus: 5,
  current: 4,
  // brandedMedium: 3,
  // genericMedium: 2,
  brandedSmall: 1,
  genericSmall: 0
}
