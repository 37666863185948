import { doAtInternetAction } from '../../domain/analytics/ATInternetService'
import { deleteCookie, getMainCookieDomain } from '../../domain/utils/cookie'
import { selectHasUserConsentedForTracking } from '../cmp/cmp.selectors'
import { registerConsent } from '../cmp/cmpSlice'

export const handleConsent =
  ({ getState }) =>
  next =>
  action => {
    if (action.type === registerConsent.type) {
      const consentTrackingBefore = selectHasUserConsentedForTracking(getState())
      const r = next(action)
      const consentTrackingAfter = selectHasUserConsentedForTracking(getState())

      if (!consentTrackingBefore && consentTrackingAfter) {
        doAtInternetAction(tag => {
          global.ATInternet.Utils.consentReceived(true)
          tag.clientSideUserId.store()
        })
      } else if (consentTrackingBefore && !consentTrackingAfter) {
        global.ATInternet.Utils.consentReceived(false)
        deleteCookie('atuserid', getMainCookieDomain(location.host))
      }

      return r
    }

    return next(action)
  }

export default handleConsent
