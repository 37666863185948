export const getPoiAdditionnalInfo = poi => poi?.additionalInfo

export const getPoiPrice = poi => getPoiAdditionnalInfo(poi)?.price

export const getPoiPriceLabel = poi => {
  const price = getPoiPrice(poi)
  return price?.value ? `${price.value} ${price?.currency ?? '€'}` : undefined
}

export const getPoiFuels = poi => getPoiAdditionnalInfo(poi)?.fuels

export const getPoiFuelByFuelId = (poi, fuelId) => getPoiFuels(poi)?.find(({ id }) => id === fuelId)

export const getPoiRubric = poi => poi?.rubric

export const getPoiRubricId = poi => getPoiRubric(poi)?.id
