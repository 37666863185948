export const COOKIE_TCF_NAME = 'euconsent-v2'
export const COOKIE_DIDOMI_NAME = 'didomi_token'
export const COOKIE_UTIQ_NAME = 'utiq_consent_status'

const getAllCookieNames = () => {
  const cookies_parts = document.cookie.split(';')
  return cookies_parts.map(keyvalue => {
    const [name] = keyvalue.split('=')
    return name.trim()
  })
}

export const deleteCookie = (name, domain, path = '/') => {
  const domainPart = domain ? `;domain=${domain}` : ''
  const cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=${path}${domainPart}`
  document.cookie = cookie
}

export const deleteCookies = (domain, exceptions = []) => {
  const cookieNames = getAllCookieNames()
  const cookiesToDelete = cookieNames.filter(name => !exceptions.includes(name))
  console.log('deleteCookies:', cookiesToDelete.join(', '), 'on domain', domain)
  cookiesToDelete.forEach(name => deleteCookie(name, domain))
}

export const getMainCookieDomain = hostname => hostname.substring(hostname.indexOf('.'))
