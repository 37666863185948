import { search } from '../search/search.actions'

import {
  findAddressforGeolocation,
  findLocationForGeolocation,
  geocodeForward
} from '../../dataSource/address/address.request'
import { fetchCityDescription } from '../../dataSource/seo/seo.request'
import { LOCALES } from '../../domain/i18n'
import { getSeoCityPostcode } from '../../domain/seo/seo'
import { hasFictiveZipOfCityWithDistricts, isZipDashCity } from '../../domain/utils/location'
import { selectLocale } from '../navigation/navigation.selectors'
import { geocodeForwardCtx, searchServiceCtx } from '../search/search.service.constants'
import { setSearchTerms } from '../search/searchSlice'
import { selectFirstAddress, selectIsAddressAMunicipality } from './address.selectors'
import {
  addContourToFirstAddress,
  loadAddressError,
  loadAddressRequest,
  loadAreaFromGeocode,
  loadDescription,
  loadSingleAddressSuccess
} from './addressSlice'

const handleError = dispatch => error => {
  dispatch(loadAddressError({ error }))

  throw error
}

export const searchSingleAddress =
  ({ terms, options }) =>
  (dispatch, getState) => {
    dispatch(loadAddressRequest())

    const locale = selectLocale(getState())

    const geographicType = hasFictiveZipOfCityWithDistricts(terms)
      ? geocodeForwardCtx.filter.town
      : isZipDashCity(terms)
        ? geocodeForwardCtx.filter.municipality
        : undefined

    return geocodeForward({ location: terms, locale, geographicType })
      .then(({ addresses, viewport }) => {
        dispatch(
          loadSingleAddressSuccess({
            addresses,
            viewport,
            options
          })
        )
      })
      .catch(handleError(dispatch))
  }

export const searchArea = (location, geographicType) => (dispatch, getState) => {
  const locale = selectLocale(getState())
  return geocodeForward({ location, locale, geographicType })
    .then(({ addresses, viewport }) => {
      dispatch(
        loadAreaFromGeocode({
          addresses,
          viewport
        })
      )
    })
    .catch(handleError(dispatch))
}

export const searchMultipleAddresses = args => (dispatch, getState) => {
  const locale = selectLocale(getState())
  return search({
    ...args,
    searchFilterType: locale === LOCALES.fr_FR ? searchServiceCtx.filter.default : searchServiceCtx.filter.address
  })(dispatch, getState)
}

export const searchLocationFromPoint =
  (lngLat, filter = searchServiceCtx.filter.default) =>
  (dispatch, getState) => {
    dispatch(loadAddressRequest())
    const locale = selectLocale(getState())
    return findLocationForGeolocation({ ...lngLat, filter, contour: 1, locale })
      .then(({ addresses, viewport }) => {
        dispatch(
          loadSingleAddressSuccess({
            addresses,
            viewport
          })
        )

        if (addresses?.[0]?.contour) {
          dispatch(addContourToFirstAddress(addresses[0].contour))
        }
      })
      .then(() => {
        const label = selectFirstAddress(getState())?.label
        if (label) dispatch(setSearchTerms(label))
      })
      .catch(handleError(dispatch))
  }

export const searchAddressFromPoint = lngLat => (dispatch, getState) => {
  dispatch(loadAddressRequest())

  const locale = selectLocale(getState())
  return findAddressforGeolocation({ ...lngLat, locale })
    .then(({ addresses, viewport }) => {
      const { lat, lng } = addresses?.[0]?.coordinates || {}
      const options =
        lat && lng
          ? {
              targetCenter: [lng, lat],
              targetZoom: 17
            }
          : undefined

      dispatch(
        loadSingleAddressSuccess({
          addresses,
          viewport,
          options
        })
      )
    })
    .then(() => {
      const label = selectFirstAddress(getState())?.label
      if (label) dispatch(setSearchTerms(label))
    })
    .catch(handleError(dispatch))
}

export const searchCityFromPoint = lngLat => (dispatch, getState) =>
  searchLocationFromPoint(lngLat, searchServiceCtx.filter.town)(dispatch, getState)

export const searchDepartmentFromPoint = lngLat => (dispatch, getState) =>
  searchLocationFromPoint(lngLat, searchServiceCtx.filter.county)(dispatch, getState)

export const searchRegionFromPoint = lngLat => (dispatch, getState) =>
  searchLocationFromPoint(lngLat, searchServiceCtx.filter.state)(dispatch, getState)

export const getSearchAddress = () => (__BROWSER__ ? searchMultipleAddresses : searchSingleAddress)

export const searchAddress = getSearchAddress()

export const searchCityDescription = () => (dispatch, getState) => {
  if (!selectIsAddressAMunicipality(getState())) return Promise.resolve()

  const address = { ...selectFirstAddress(getState()) }
  if (address.geocode_level === searchServiceCtx.geocodeLevel.town) {
    address.postcode = getSeoCityPostcode(address.postcode)
  }
  if (!address.postcode) return Promise.resolve()
  return fetchCityDescription(address).then(({ description }) => {
    if (description) {
      dispatch(loadDescription(description))
    }
  })
}

export const searchAddressContour =
  (location, geographicType = geocodeForwardCtx.filter.municipality) =>
  (dispatch, getState) => {
    const locale = selectLocale(getState())

    return geocodeForward({
      location,
      contour: 1,
      geographicType,
      locale
    })
      .then(({ addresses }) => {
        if (!addresses?.[0]?.contour) return
        dispatch(addContourToFirstAddress(addresses[0].contour))
      })
      .catch(error => {
        console.error('search location contour:', error)
      })
  }

export const setAddressFromAmbiguity = address => (dispatch, getState) => {
  if (address.geocode_level === searchServiceCtx.geocodeLevel.addressPoint) {
    searchAddress({
      terms: address.label
    })(dispatch, getState)
  } else {
    dispatch(loadSingleAddressSuccess({ addresses: [address] }))
  }
}
