export const PAGE_CLASSES = {
  AddressHomePage: 'AddressHomePage',
  SearchHomePage: 'SearchHomePage',
  AddressPage: 'AddressPage',
  PoiPage: 'PoiPage',
  ItineraryHomePage: 'ItineraryHomePage',
  ItineraryResultPage: 'ItineraryResultPage',
  RoadbookPage: 'RoadbookPage',
  LayerPage: 'LayerPage',
  LandingPage: 'LandingPage',
  DirectoryPage: 'DirectoryPage',
  //
  withSubHeader: 'withSubHeader',
  withFilters: 'withFilters',
  withToaster: 'withToaster',
  withBackButton: 'withBackButton',
  withUserAccountButton: 'withUserAccountButton',
  withHeaderButton: 'withHeaderButton',
  withFullscreenMap: 'withFullscreenMap',
  withDetail: 'withDetail',
  withDetailSubHeader: 'withDetailSubHeader',
  withPoisOnRoute: 'withPoisOnRoute',
  withMenuCategoriesPE: 'withMenuCategoriesPE',
  withSuggest: 'withSuggest',
  withMenuPEDisplayed: 'withMenuPEDisplayed',
  withMapToolsPEDisplayed: 'withMapToolsPEDisplayed',
  withPoiPopinDisplayed: 'withPoiPopinDisplayed',
  //
  mainDetail: 'mainDetail',
  headerDetail: 'headerDetail'
}
